@import "../../styles/variables.module";

.floatMenu {
  position: fixed;
  top: 50%;
  right: 2rem;
  background-color: $Color_Gray500;
  box-shadow: 0px 3px 15px rgba(8, 8, 9, 0.65);
  border-radius: 6px;
  list-style: none;
  margin: -50px 0 0 0;
  z-index: 10;
  padding: 0;
  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    cursor: pointer;
    &:hover {
      background-color: #1A1F22;
    }
  }
}

.languageList {
  list-style: none;
  padding: 0;
  margin: 1rem;
  & li {
    & label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
@media (width < 960px) {
  .floatMenu {
    right: 0.5rem;
    top: auto;
    bottom: 2rem;
  }
}
