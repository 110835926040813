@import './variables.module.scss';

html {
  background-attachment: fixed;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $Main_FontFamily;
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

a {
  color: $Color_Green500;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.page-container {
}
.page-content {
  position: relative;
  width: calc(100% - $Sidebar_Width);
  &-noSidebar {
    width: 100%;
  }
}

.swiper {
  overflow: visible !important;
  &-slide {
    & img {
      transform: scale(0.8);
      transition: all 0.3s;
    }
    &-active {
      img {
        transform: scale(1);
      }
    }
  }

  &-scrollbar {
    width: 960px !important;
    margin: 0 auto;
    position: relative !important;
    height: 6px !important;
    background-color: $Color_Gray500;
    bottom: -2rem !important;
    &-drag {
      background-color: $Color_Green500;
    }
  }
}

/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
*/
@font-face {
  font-family: 'Source Sans Pro';
  /*a name to be used later*/
  src: url('./fonts/SourceSans/SourceSansPro-Regular.ttf');
  /*URL to font*/
  font-weight: 400;
}

@font-face {
  font-family: 'Source Sans Pro';
  /*a name to be used later*/
  src: url('./fonts/SourceSans/SourceSansPro-Bold.ttf');
  /*URL to font*/
  font-weight: 700;
}

@font-face {
  font-family: 'Mukta';
  /*a name to be used later*/
  src: url('./fonts/Mukta/Mukta-Regular.ttf');
  /*URL to font*/
  font-weight: 400;
}

@font-face {
  font-family: 'Mukta';
  /*a name to be used later*/
  src: url('./fonts/Mukta/Mukta-Bold.ttf');
  /*URL to font*/
  font-weight: 700;
}

@media (width < 960px) {

  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  .swiper {
    &-scrollbar {
      width: 320px !important;
    }
  }
}