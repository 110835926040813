@import './src/styles/variables.module';

.footerWrapper {
  font-size: 20px;
  line-height: 33px;
  color: $Color_LightGray500;
  & a {
    color: $Color_LightGray500;
    &:hover {
      text-decoration: underline;
    }
  }

}
.footerTitle {
  color: $Color_White500;
}


.footerButton {
  display: none;
}


.footerList {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 210px;
  & li {
    line-height: 20px;
    padding: 6px 0;
  }
  & a {
    color: $Color_LightGray500;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footerLinkGroup {
  display: flex;
  gap: 2rem
}

@media (width < 960px) {
  .footerLinkGroup {
    display: block;
    & a {
      display: block;
    }
  }

  .footerWrapper {
    .footerButton {
      background-color: $Color_Green500;
      border-radius: 4px;
      width: 90%;
      height: 64px;
      line-height: 64px;
      color: #fff;
      display: block;
      margin: 0 auto 1rem auto;
      text-align: center;
    }
  }
}