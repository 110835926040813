@import "../../styles/variables.module";

.brandLogo {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 120px;
}

.brandLogoText {
  flex: 10;
  text-align: left;
  margin: 20px 0 0 0;
  & svg {
    width: 70px;
  }
}

.brandLogoIcon {
  flex: 2;
  height: 38px;
  & svg {
    width: 38px;
    height: 38px;
  }
}


@media (width < 960px) {
  .brandLogo {
    flex-grow: 1;
  }
}