.snackbarContainerRoot {
  bottom: 0 !important;
  width: inherit !important;
  right: 0 !important;
  margin: 0!important;
  &> div {
    width: 100%;
  }
}

.snackbarRoot {
}