@import "../../styles/variables.module";

.headerLayout {
  background: #16191C;
  box-shadow: 0 3px 15px rgba(8, 8, 9, 0.65);
}

.navigationItemButton {
  background-color: $Color_Green500;
  border-radius: 4px;
  color: #fff;
  padding: 0.2rem 1rem;
}
