@import '../../styles/variables.module.scss';

.modalCloseButton {
  position: absolute !important;
  top: 1.5rem;
  right: 1.5rem;
  background-color: $Color_Gray500 !important;
  border-radius: 6px !important;

  & svg {
    color: $Color_LightGray500;
  }
}

.backdropSuccess {
  background: linear-gradient(332.15deg, #084E08 0%, rgba(12, 57, 14, 0.632292) 16%, rgba(18, 21, 23, 0) 45.02%) !important;
}

.backdropError {
  background: linear-gradient(332.15deg, #85160A 0%, rgba(129, 20, 8, 0.632292) 16%, rgba(18, 21, 23, 0) 45.02%) !important;
}