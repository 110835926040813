@import "../../styles/variables.module";

.navigationRoot {
  margin: 0 2rem;
  align-items: center;
  justify-content: flex-start;
  & a {
    color: #fff;
  }
}

.navigationRootEnd {
  align-items: center;
  justify-content: flex-end;
}

.navigationItemButton {
  background-color: $Color_Green500;
  border-radius: 4px;
  color: #fff;
  padding: 0.2rem 1rem;
}

.navigationItemLink {
  color: #fff;
  padding: 0 1rem;

  &:hover {
    text-decoration: underline;
  }
}