$Main_FontFamily: Mukta, Source Sans Pro, sans-serif;

$Color_Background: #121517;
$Color_LightBackground: #EFF2F4;
$Color_Gray500: #24292d;
$Color_Gray400: #2d3439;
$Color_Gray300: #3f4950;
$Color_LightGray500: #818891;
$Color_LightGray400: #9da2aa;
$Color_DarkGray400: #24292D;
$Color_DarkGray500: #1B1F22;
$Color_White500: #ffffff;
$Color_DarkWhite500: #D9DCDE;
$Color_DarkWhite400: #C8CDD0;
$Color_Green500: #16db65;
$Color_DarkGreen500: #12BA55;
$Color_Yellow500: #ffd639;
$Color_Red500: #ee321f;
$Color_Violette500: #9d4edd;
$Color_Blue500: #0466c8;
$Sidebar_Width: 296px;

:export {
  Color_Gray500: $Color_Gray500;
  Color_Yellow500: $Color_Yellow500;
  Color_Gray300: $Color_Gray300;
  Color_Green500: $Color_Green500;
  Color_DarkGreen500: $Color_DarkGreen500;
  Color_White500: $Color_White500;
  Color_DarkWhite500: $Color_DarkWhite500;
  Color_DarkWhite400: $Color_DarkWhite400;
  Color_LightGray500: $Color_LightGray500;
  Color_DarkGray400: $Color_DarkGray400;
  Color_DarkGray500: $Color_DarkGray500;
  Color_Red500: $Color_Red500;
  Color_Violette500: $Color_Violette500;
  Color_Blue500: $Color_Blue500;
  Color_Background: $Color_Background;
  Color_LightBackground: $Color_LightBackground;
  Sidebar_width: $Sidebar_Width;
  Main_FontFamily: $Main_FontFamily;
}
